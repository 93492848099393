<template>
  <b-container fluid>
    <b-row>
      <b-col sm="12">
        <b-row>
          <b-col class="profile-center">
          <iq-card>
              <template v-slot:headerTitle>
                <h4 class="card-title">Detalles De Provedor</h4>
              </template>
              <template v-slot:body>
                  <b-row align-v="center">
                    <b-form-group class="col-md-4">
                      <h6 class="custom-text">NOMBRE: </h6><h5>{{ provider.fantasy_name }}</h5>
                    </b-form-group>
                    <b-form-group class="col-md-4">
                      <h6 class="custom-text">RAZÓN SOCIAL: </h6><h5>{{ provider.business_name }}</h5>
                    </b-form-group>
                     <b-form-group class="col-md-4">
                      <h6 class="custom-text">CÓDIGO DE CUENTA: </h6><h5>{{ provider.code_account }}</h5>
                    </b-form-group>
                    <b-form-group class="col-md-4">
                      <h6 class="custom-text">RUT: </h6><h5>{{ provider.rut }}</h5>
                    </b-form-group>
                    <b-form-group class="col-md-4" >
                      <h6 class="custom-text">GIRO: </h6><h5>{{ provider.gire }}</h5>
                    </b-form-group>
                    <b-form-group class="col-md-4">
                      <h6 class="custom-text">COMUNA: </h6><h5>{{ provider.comuna.name }}</h5>
                    </b-form-group>
                    <b-form-group class="col-md-4">
                      <h6 class="custom-text">DIRECCIÓN: </h6><h5>{{ provider.address }}</h5>
                    </b-form-group>
                  </b-row>
              </template>
            </iq-card>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'ShowProviders',
  mounted () {
    this.$store.commit("SET_BREADCRUMB", this.items);
    core.index()
  },
  data () {
    return {
      id: this.$route.params.id,
      items: [
        {
          html: 'Inicio',
          to: '/'
        },
        {
          text: 'Listado de proveedores',
          to: '/provedores/list'
        },
        {
          text: 'Detalles del proveedor',
          active: true
        }
      ]
    }
  },
  created () {
    this.showProviders(this.id)
  },
  methods: {
    ...mapActions({
      showProviders: 'providers/showProviders'
    })
  },
  computed: {
    ...mapGetters({
      provider: 'providers/getProvider'
    })
  }
}
</script>
<style>
.custom-text {
  font-size: 0.7em !important;
  color: #a2a0a0 !important;
}
</style>
